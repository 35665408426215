<template>
   <div class="flex-align" style="gap: 8px">
     <input-date style="flex: 1"
      v-model="value.start" @input="res => onInput('start', res)" />
     <span>~</span>
     <input-date style="flex: 1" :prohibitBeforeDate="true" :beforeDate="value.start"
      v-model="value.end" @input="res => onInput('end', res)" />
   </div>
</template>
<script>
import InputDate from '@/components/module/InputDate.vue';
export default {
   name: 'InputDatePeriod',
   components: {
      InputDate
   },
   mixins: [],
   props: {
     value: {
        type: Object
     }
   },
   created() {
    if(!this.value.start) {
      this.value.start = this.dayjs().format('YYYY-MM-DD');
    }
    if(!this.value.end) {
      this.value.end = this.dayjs().add(1, 'month').format('YYYY-MM-DD');
    }
   },
   data() {
     return {
       
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
      onInput(key, res) {
         this.value[key] = res;
         this.$emit('update:value', this.value);
      }
   }
};
</script>
<style lang='' scoped>
</style>